#Login {
    position: relative;
    margin: auto;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: center;
    font-family: NotoSans;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 13px;
    justify-self: center;
    color: rgba(13,18,101,1);
    pointer-events:inherit;
}
#Mail_Form {
    position: absolute;
    width: 372px;
    height: 44px;
    left: 240.818px;
    top: 366.025px;
    overflow: visible;
}
#Rectangle_1055 {
    fill: rgba(255,255,255,1);
    stroke: rgba(227,226,230,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rectangle_1055 {
    position: absolute;
    overflow: visible;
    width: 372px;
    height: 44px;
    left: 0px;
    top: 0px;
}
#Mail_Input {
    left: 26.521px;
    top: 12.5px;
    position: absolute;
    overflow: visible;
    width: 126px;
    white-space: nowrap;
    text-align: left;
    font-family: NotoSans;
    font-weight: 500;
    font-size: 14px;
    color: rgba(150,150,159,1);
}
#Password_form {
    position: absolute;
    width: 372px;
    height: 44px;
    left: 240.818px;
    top: 468.025px;
    overflow: visible;
}
#Rectangle_285_gt {
    fill: rgba(255,255,255,1);
    stroke: rgba(227,226,230,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rectangle_285_gt {
    position: absolute;
    overflow: visible;
    width: 372px;
    height: 44px;
    left: 0px;
    top: 0px;
}
#Password_Input {
    left: 26.521px;
    top: 12.5px;
    position: absolute;
    overflow: visible;
    width: 116px;
    white-space: nowrap;
    text-align: left;
    font-family: NotoSans;
    font-weight: 500;
    font-size: 14px;
    color: rgba(150,150,159,1);
}
#Password {
    left: 240.84px;
    top: 439.525px;
    position: absolute;
    overflow: visible;
    width: 66px;
    white-space: nowrap;
    text-align: left;
    font-family: NotoSans;
    font-weight: 600;
    font-size: 14px;
    color: rgba(0,0,0,1);
}
#Forgot_Password {
    position: absolute;
    width: 122px;
    height: 19px;
    left: 240.84px;
    top: 536.525px;
    overflow: visible;
}
#Forgot_password_ {
    left: 0px;
    top: 69px;
    position: absolute;
    overflow: visible;
    width: 123px;
    white-space: nowrap;
    text-align: left;
    font-family: NotoSans;
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    color: rgba(13,18,101,1);
}

#Forgot_password_:hover{
    text-decoration: underline;
    font: normal normal 600 14px/13px NotoSans;
}
#register {
    position: absolute;
    width: 328px;
    height: 19px;
    left: 240.84px;
    top: 644.525px;
    overflow: visible;
    font-family: NotoSans;
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    letter-spacing: 0;
    text-align: left;
}
#Interested_in_registering_Cont_g {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 249px;
    white-space: nowrap;
    text-align: left;
    font-family: NotoSans;
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    letter-spacing: 0;
    color: rgba(0,0,0,1);
}
#Email {
    left: 240.84px;
    top: 337.525px;
    position: absolute;
    overflow: visible;
    width: 39px;
    white-space: nowrap;
    text-align: left;
    font-family: NotoSans;
    font-weight: 600;
    font-size: 14px;
    color: rgba(0,0,0,1);
}
#Logo_g {
    width: 22rem;
    height: auto;
    overflow: visible;
    z-index:99;
}
#Logo_ha {
    width: 100%;
    height: auto;
    overflow: visible;
}
#Login_component {
    position: absolute;
    width: 140px;
    height: 44px;
    left: 241px;
    top: 578.025px;
    overflow: visible;
}
#Login_component:hover #Rectangle_285_gq{
    stroke: rgba(255,255,255,1);
    fill: rgba(13,18,101,1);
}

#Login_component:hover #Login{
    color: rgba(255,255,255,1);
}

#Rectangle_285_gq {
    fill: rgba(255,255,255,1);
    stroke: rgba(13,18,101,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
    pointer-events:inherit;
}
.Rectangle_285_gq {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 158px;
    height: 62px;
    left: 0px;
    top: 0px;
}
.login-button {
    border-style: hidden;
    background-color: white;
    text-align: center;
    margin: auto;
}
.login-form-input {
    border-style: none;
    width: 310px;
    font-family: NotoSans;
    font-weight: 500;
}
#Seigaiha_Accent_gn {
    position: absolute;
    width: 405.584px;
    height: 352.625px;
    left: 73.5%;
    top: 274px;
    overflow: hidden;
}
#Group_949_go {
    position: absolute;
    width: 405.584px;
    height: 352.625px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#contactUs {
    font: normal normal medium 14px/13px NotoSans;
    letter-spacing: 0px;
    text-decoration: none;
    color: #0D1265;
    text-align: left;
}

#contactUs:hover {
    text-decoration: underline;
    font: normal normal 600 14px/13px NotoSans;
}

.mobile-home{
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: filter 1s, box-shadow 1s;
}

.mobile-home::before {
    position: absolute;
    display: block;
    content: ' ';
    width: 100%;
    height: 100%;
    background-image: image-set(
        url("/public/Group_949.png") 1x,
        url("/public/Group_949@2x.png") 2x);
    background-position: 100% 52%;
    background-size: 75% auto;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    opacity: 35%;
}

.mobile-home-logo{
    width: 68.5333333%;
    height: 3.202%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-home-logo #Logo_ha {
    position: relative;
    width: 100%;
    height: auto;
}

/* Landscape orientation */
@media screen and (orientation: landscape) {
    .mobile-home-logo #Logo_ha {
        position: relative;
        width: 70%;
    }
}

.mobile-home-subtext{
    font: normal normal normal 16pt NotoSans;
    text-align: center;
    margin-top: 2%;
}

.mobile-footer-watermark {
    font: normal normal 700 8pt NotoSans;
    color: #BDBDBF;
    text-align: center;
}